<template>
  <b-row>
    <b-col>
      <b-table striped hover :items="conc" :fields="fields" stacked="md" responsive table-class="mb-0">
        <template #cell(seller)="{item}">
          <a :href="'https://www.wildberries.ru/catalog/' + item.art_wb + '/detail.aspx?targetUrl=XS'" target="_blank">
            {{item.seller}} 23.02 14:40 <b-icon icon="box-arrow-up-right"></b-icon>
          </a>
                  </template>
                  <template #cell(salePrice)="{item}">
                    {{item.salePrice}} ({{item.dohod}})
                  </template>
                  <template #cell(rating)="{item}">
                    {{item.rating}} ({{item.feedbackCount}})
                  </template>
<!--                  <template #cell(qty)="{item}">-->
<!--                    in-{{item.qty}}, out-{{item.qtyInWh}}-->
<!--                  </template>-->
                </b-table>
              </b-col>
            </b-row>
          </template>
          <script>
          export default {
            name: 'conc-artwb',
            props: ["artwb", "conc"],
            data: () => ({
              isLoading: false,
              fields: [
                {key: "seller", sortable: true, label: "Продавец"},
                {key: "salePrice", sortable: false, label: "Цена/Доход"},
                {key: "qty", sortable: false, label: "остатки"},
                {key: "rating", sortable: false, label: "рейтинг"}
              ]
            }),
            methods: {
              // getBgClass: function(value) {
              //   if (value > 0) return 'bg-success'
              //   if (value <= 0) return 'bg-danger'
              // }
            },
            mounted() {
              this.isLoading = true

              // fetch(`http://${process.env.VUE_APP_API_SERVER}:${process.env.VUE_APP_API_PORT}/report/ostatki/${this.artwb}`, {
              //   method: 'GET',
              //   mode: 'cors',
              //   cache: 'no-cache',
              //   credentials: 'same-origin',
              //   headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
              // })
              //     .then(resp => {
              //       return resp.json()
              //     })
              //     .then(resp => {
              //       this.ostatki = resp
              //       this.isLoading = false
              //     })
              //     .catch(err => {
              //       console.log(err)
              //       this.isLoading = false
              //     })
            }
          }
          </script>