<template>
  <div>
    <template v-if="isLoading">
      <b-skeleton animation="throb" width="55%"></b-skeleton>
    </template>
    <template v-else>
      VIP:
      <span :title="`Остатки на наших складах: мск: ${ostatki.mskIn} | спб: ${ostatki.spbIn}`" class="text-white pl-1 pr-1 mr-1" :class="getBgClass(ostatki.spbIn+ostatki.mskIn)">{{ ostatki.spbIn+ostatki.mskIn }}</span>
      <span :title="`Остатки у поставщиков: мск: ${ostatki.mskExport} | спб: ${ostatki.spbExport}`" class="text-white pl-1 pr-1 mr-2" :class="getBgClass(ostatki.spbExport+ostatki.mskExport)">{{ ostatki.spbExport+ostatki.mskExport }}</span>
      WB:
      <span title="Остатки на складе вб" class="text-white pl-1 pr-1 mr-1" :class="getBgClass(ostatki.wb)">{{ ostatki.wb }}</span>
      <span title="Транслируем на вб" class="text-white pl-1 pr-1 mr-2" :class="getBgClass(ostatki.wbPssp)">{{ ostatki.wbPssp }}</span>
    </template>

  </div>
</template>
<script>
export default {
  name: 'ostatki-artwb',
  props: ["barcode", "artwb"],
  data: () => ({
    isLoading: false,
    ostatki: {
      spbIn: 0,
      spbExport: 0,
      mskIn: 0,
      mskExport: 0,
      wb: 0,
      wbPssp: 0
    }
  }),
  methods: {
    getBgClass: function(value) {
      if (value > 0) return 'bg-success'
      if (value <= 0) return 'bg-danger'
    }
  },
  mounted() {
    this.isLoading = true
    fetch(`${process.env.VUE_APP_SERVER_API}/report/ostatki/${this.artwb}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
    })
        .then(resp => {
          return resp.json()
        })
        .then(resp => {
          this.ostatki = resp
          this.isLoading = false
        })
        .catch(err => {
          console.log(err)
          this.isLoading = false
        })
  }
}
</script>