<template>
    <b-row>
      <b-col lg="12">
        <b-row class="mt-2 mb-2">
          <b-col lg="12">
            <b-overlay :show="isLoadingStat" rounded="lg">
              <b-list-group @click="getStats()">
                <b-list-group-item href="#"   class="flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">всего: {{ stats.vip }}, с остатками: {{ stats.vipOnSale }}</h5>
                    <small>{{ statsUpd }} </small>
                  </div>
                  карточек WB: {{ stats.wb }}, транслируем: {{stats.wbOnSale}} <br>
                  в продаже: all: {{stats.wbFactip+stats.wbFactooo+stats.wbFactvipl}}, ip:{{stats.wbFactip}}, ooo:{{stats.wbFactooo}}, vipl:{{stats.wbFactvipl}}
                  <br>
                  <!--              Карточек: all:{{stats.wbFactCardsip+stats.wbFactCardsooo}}, ip: {{stats.wbFactCardsip}}, ooo: {{stats.wbFactCardsooo}}<br>-->
                  В очереди на изменение цен: {{ stats.queueCreated }} | с ошибками: {{stats.queueError}}
                </b-list-group-item>
              </b-list-group>
            </b-overlay>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4">
            <b-form-group label="" label-for="tags-component-select">
              <b-form-tags
                  id="tags-component-select"
                  v-model="selModels"
                  size="sm"
                  class="mb-1"
                  add-on-change
                  no-outer-focus
                  @tag-state="recalcTotalRows"
                  @input="recalcTotalRows"
              >
                <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                  <b-form-select
                      v-bind="inputAttrs"
                      v-on="inputHandlers"
                      :disabled="disabled || availableOptions.length === 0"
                      :options="availableOptions"
                      size="sm"
                  >
                    <template #first>
                      <!-- This is required to prevent bugs with Safari -->
                      <option disabled value="">Выбрать модель...</option>
                    </template>
                  </b-form-select>
                  <ul v-if="tags.length > 0" class="list-inline d-inline-block mt-2" style="margin-bottom: 0">
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag
                          @remove="removeTag(tag)"
                          :title="tag"
                          :disabled="disabled"
                          variant="primary"
                      >{{ tag }}</b-form-tag>
                    </li>
                  </ul>
                </template>
              </b-form-tags>
            </b-form-group>
          </b-col>
          <b-col lg="4">

          </b-col>
          <b-col lg="4">
            <b-card no-body class="p-1">

            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <div class="col-12 div-table">
        <b-table
            fixed
            bordered
            sticky-header
            small
            head-variant="light"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            :items="filterModel"
            :fields="fields"
            :busy="isLoading"
            :tbody-tr-class="rowClass"
            :current-page="currentPage"
            :per-page="perPage"
            stacked="md"
            caption-top
        >
          <template #table-colgroup="scope">
            <col
                v-for="field in scope.fields"
                :key="field.key"
                :style=calcColWidth(field.key)
            >
          </template>

          <template #table-caption>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                size="sm"
                class="my-0"
                align="right"
            ></b-pagination>
          </template>

          <template #table-busy>
            <div class="text-center">
              <b-spinner variant="primary" label="loading..."></b-spinner>
            </div>
          </template>
          <template #cell(name)="row">
            <a href="#" @click.prevent="row.toggleDetails">{{row.item.seller}} | {{ row.item.model }} </a><br>
            <span title="Карточек  всего" @click.prevent="row.toggleDetails">{{row.item.allQtyCard}} {{ row.detailsShowing ? '(свернуть)' : '(все диоптрии)'}}</span>
          </template>
          <template #cell(prices)="">
<!--            <price-box :item="row.item" @setall="setAll($event)" @setToWbAllModel="setToWbAllModel($event)" />-->
<!--            <price-box :item="row.item.arts" @setall="setAll($event)" @setToWbAllModel="setToWbAllModel($event)" />-->

          </template>
<!--          <template #cell(gays)="{item}"> -->
<!--            <b-row>-->
<!--              <b-col sm="12" style="font-family: Arial;font-size: 16px">-->
<!--                <b-badge title="Самая низкая цена" class="ml-1" variant="light">{{Math.min(...item.arts.map(el=>Math.min(...el.subSellers.filter(e => e.qty+e.qtyInWh>0).map(e => e.salePrice))))}}</b-badge>-->
<!--                <b-badge title="Всего карточек конкурентов" class="ml-1" variant="secondary">{{item.arts.filter(e => e.other.length>0).length + item.arts.filter(e => e.subSellers.length>0).length}}</b-badge>-->
<!--                <b-badge title="Наша цена выше минимальной" class="ml-1" variant="warning">{{item.arts.filter(el => el.salePrice>Math.min(...el.subSellers.filter(e => e.qty+e.qtyInWh>0).map(e => e.salePrice))).length}}</b-badge>-->
<!--                <b-badge title="У нас есть на остатках, а у них нету (криво работает)" class="ml-1" variant="success">{{item.arts.filter(el => el.wb+el.wbPssp>0  && el.subSellers.filter(e => e.qty+e.qtyInWh==0).length>0).length}}</b-badge>-->
<!--                <b-badge title="У нас нет остатков, у них есть" class="ml-1" variant="danger">{{item.arts.filter(el => el.wb+el.wbPssp==0  && el.subSellers.filter(e => e.qty+e.qtyInWh>0).length>0).length}}</b-badge>-->
<!--              </b-col>-->
<!--            </b-row>-->

<!--          </template>-->
          <template #row-details="row">
<!--            {{row.item.arts}}-->
            <DohodAndPrice :arts="row.item.arts" :zakup="row.item.arts[0].zakup" :key="'dohod-'+row.item.model" />
          </template>


        </b-table>
      </div>
    </b-row>

</template>
<style scoped>
input[type="text"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input[type="number"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
<script>
import DohodAndPrice from "@/components/products/cols/DohodAndPrice";
// import PriceBox from "@/components/products/list2/PriceBox";


export default {
  name: "MainTable",
  components: { DohodAndPrice},
  props: [],
  data: () => ({
    totalRows: 0,
    selModels: [],
    models: ['Air Optix Aqua'],
    selTypes: [],
    currentPage: 1,
    perPage: 100,
    isLoading: true,
    isLoadingStat: true,
    filter: '',
    filterOn: ['attr', 'name'],
    products: [],
    statsUpd: '',
    promoPrice: [],
    stats: {
      vip: 0,
      vipOnSale: 0,
      wb: 0,
      wbOnSale: 0,
      queueCreated: 0,
      queueError: 0,
      wbFactip: 0,
      wbFactooo: 0,
      wbFactvipl: 0,
    },
    fields: [
      {key: "name", sortable: true, label: "Товар"},
      {key: "prices", sortable: true, label: "Цены и доход"},
      {key: "gays", sortable: true, label: "Конкуренты"},
      {key: "sales", sortable: true, label: "Продажи"},
      // {key: "actions", sortable: false, label: ""},
    ],
    sorters: {
  qty: false}
  }),
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    setAll({model, salePrice, dohod}) {
       this.products.filter(el => el.model ===model).forEach(el => {
         el.arts.forEach(els =>{
           els.salePrice=salePrice
           els.dohod=dohod
         })
       })
    },
    setToWbAllModel({model, salePrice, dohod, seller}) {
      fetch(`${process.env.VUE_APP_SERVER_API}/report/setToWbAllModel`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify({model, salePrice, dohod, seller})
      })
          .then(resp => {
            return resp.json()
          })
          .then(({status}) => {
            if (status) this.alertMsg('Загружаем цены | Успешно', 'Новые цены в очереди на заливку на вб', 'success')
            if (!status) this.alertMsg('Загружаем цены | Ошибка', 'ошибка сервера', 'danger')
          })
          .catch(err => {
            this.alertMsg('Загружаем цены | Ошибка', `ошибка загрузки на сервер: ${err}`, 'danger')
          })
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "yes") return "table-success";
      if (item.status === "up") return "table-info";
    },
    async loadData() {
      const _ = require("lodash")
      this.isLoading = true
      fetch(`${process.env.VUE_APP_SERVER_API}/report/prodList2`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}})
          .then(resp => resp.json())
          .then(data => {
            if (data.length <= 0) throw "нет товаров";

            const vip = _.chain(data)
                .groupBy("smodel")
                .map((value, smodel) => ({
                  model: value[0].model,
                  smodel: smodel,
                  type: value[0].type,
                  allQtyCard: value.length,
                  arts: value,
                  seller: value[0].seller
                }))
                .value()
            console.log(vip)
           const sortedVip = _.sortBy(vip.reverse(), 'model', 'desc')
            this.products = sortedVip
            console.log(sortedVip)
            // this.products = data;
            this.totalRows = this.filterModel.length
            this.currentPage = 1
            this.isLoading = false;
            this.$emit('loading')
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
    },
    calcColWidth(key) {
      if (key === 'name') return "width:170px"
      if (key === 'prices') return "width:260px"
      if (key === 'gays') return "width:130px"
      if (key === 'ratings') return "width:120px"
      if (key === 'actions') return "width:40px"
      // return "{ width: field.key === 'sales' ? '120px' : '180px' }"
      // return "width:80px"
    },
    onFiltered(filteredItems) {
      // console.log('on filter', filteredItems.length)
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    recalcTotalRows() {
      this.totalRows = this.filterModel.length
      this.currentPage = 1
    },
    async getStats() {
      this.isLoadingStat=true
      fetch(`${process.env.VUE_APP_SERVER_API}/report/getStats`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}})
          .then(resp => resp.json())
      .then(resp => {
        if (resp.status=='ok') {
          this.stats = resp.data
          this.statsUpd = resp.update
          this.isLoadingStat=false
        }
      })
    },
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    this.getStats()
    setInterval(() => this.getStats(), 60000)

    this.loadData(this.type)

  },
  computed: {
    availableOptions() {
      return this.models.filter(opt => this.products.indexOf(opt) === -1)
    },
    filterModel() {
      let pr = this.products
      // if (this.sorters.qty) pr = pr.filter(e => e.sumQty>0)
      if (this.selModels.length==0) return pr
      return pr.filter(e => this.selModels.includes(e.model))
    },
  },
  watch: {
    'filterModel': function () {
      this.models = Array.from(new Set(this.products.map(e => e.model))).sort()
    },
    'type' : function () {
      this.loadData(this.type)
    },
    'sorters.qty': function () {
      console.log('go sorts')
    },
    'products.salePrice': function() {
      console.log('fd')
    }
  }

};
</script>

<style>
option {
  font-size: 10px;
}
.table-cur {
  background-color: rgba(183, 217, 254, 0.5) !important;
}
.table-primary {
  background-color: rgba(183, 255, 200, 0.4) !important;
}
.table-sec {
  background-color: rgba(255, 217, 254, 0.45) !important;
}

.div-table {
  font-size: 12px !important;
}

/*new*/
/* Busy table styling */
table.b-table[aria-busy='true'] {
  opacity: 0.6;
}
.custom-control-label {
  padding-top: 5px;
}
.form-control-sm{ padding: 0.25rem 0.5rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
</style>
