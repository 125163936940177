<template>
  <b-form inline style="min-width: 250px;display: flex;justify-content: flex-start">
    <b-form-input disabled type="number" :value="promoPrice" title="Цена под акцию вб">243</b-form-input>
    <b-form-input disabled type="number" v-model="dohod" :title="`${dohod} (доход) = ${promoPrice} (цена под акцию) - ${Math.ceil((this.promoPrice/100)*this.komsaPercent)} (комса wb ${komsaPercent}%) - ${zakup} (закуп) - 55 (логистика)`"></b-form-input>
    <b-form-input type="number" v-model="komsaPercent" title="% комсы вб"></b-form-input>
    <b-button style="width: 40px" size="sm" variant="success" @click="setToWbArtWb()">Ok</b-button>
  </b-form>
</template>
<script>
export default {
  name: 'promo-artwb',
  props: ["artwb", "zakup", "seller"],
  data: () => ({
    isLoading: false,
    promoPrice:0,
    komsaPercent: 8,
  }),
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    setToWbArtWb() {
      fetch(`${process.env.VUE_APP_SERVER_API}/report/setToWbArtWb`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify({art_wb:this.artwb, seller:this.seller, needPrice:this.promoPrice})
      })
          .then(resp => {
            // if (resp) alert('добавлен')
            if (resp.status) this.alertMsg('Загружаем цену | Успешно', `Новые цены в очереди на заливку на вб, id: ${resp.message}`, 'success')
            if (!resp.status) this.alertMsg('Загружаем цену | Ошибка', `ошибка сервера ${resp.message}`, 'danger')
          })
    }
  },
  mounted() {
    this.isLoading = true
    fetch(`${process.env.VUE_APP_SERVER_API}/report/promoWb/${this.artwb}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
    })
        .then(resp => {
          return resp.json()
        })
        .then(resp => {
          this.promoPrice = resp
          this.isLoading = false
        })
        .catch(err => {
          console.log(err)
          this.isLoading = false
        })
  },
  computed: {
    dohod: function () {
      return  Math.ceil(this.promoPrice - (this.promoPrice/100)*this.komsaPercent - this.zakup-55)
    }
  }
}
</script>
<style scoped>
.btn.btn-sm {
  margin: 1px 2px;
  padding: 0 5px;
}

input[type="text"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input[type="number"] {
  width: 60px;
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef!important;
  opacity: 1;
}
</style>