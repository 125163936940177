<template>
  <b-container fluid class="main-container">

    <b-row>
      <b-col md="12" class="pt-2 pb-2">
        <b-button
            type="button"
            variant="outline-primary "
            v-on:click="selType=type;isBtns=true"
            v-for="type of allTypes"
            :key="type"
            class="mt-1 mr-1"
            :class="{'active': selType===type}"
            :disabled="isBtns"
        >{{ type }}</b-button>


      </b-col>
      <b-col>
        <main-table :type="selType" @loading="showMe" />
      </b-col>

    </b-row>


  </b-container>
</template>

<script>
import MainTable from "@/components/products/MainTable";

export default {
  name: 'mainCol',
  props: ['type'],
  data: () => ({
    allTypes: [
      'Топ',
      'Прозрачные',
      'Цветные',
      'Мультифокальные',
      'Астигматические',
      'Растворы',
      'Остальное',
    ],
    selType: '',
    isBtns: false,
  }),
  components: {
    MainTable
  },
  methods: {
    showMe() {
      console.log('swho me odne')
      this.isBtns=false
    }
  },
  async mounted() {

  }
}
</script>

<style scoped>
.col {
  border: 1px solid #e7e7e7;
}
.main-container {
  background-color: #fff!important;
}
</style>
