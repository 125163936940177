<template>
    <b-row>
      <b-col xl="12">
        <b-table
            small
            bordered
            striped
            hover
            responsive
            stacked="md"
            :items="arts"
            :fields="fields"
        >
          <template #table-colgroup="scope">
            <col
                v-for="field in scope.fields"
                :key="field.key"
                :style=calcColWidth(field.key)
            >
          </template>
          <template #cell(vipName)="{item}">
            <row-artwb :artwb="item.art_wb" :item="item"/>
          </template>
        </b-table>
      </b-col>
    </b-row>
</template>
<style scoped>
input[type="text"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input[type="number"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef!important;
  opacity: 1;
}
</style>
<script>

import RowArtwb from "@/components/products/cols/ArtRow";

export default {
  props: ["arts"],
  components:{RowArtwb },
  name: "DohodAndPrice",
  data: () => ({
    curShow: "",
    fields: [
      {key: "vipName", sortable: true, label: "Товар"},
      // {key: "actions", sortable: false, label: "Действия"},
    ],
    komsaPercent: 10
  }),
  methods: {
    priceClass(conc, item) {
      try {
        if (conc.qty+conc.qtyInWh<=0) return 'light'
        if (conc.salePrice>item.salePrice) return 'light'
        if (conc.salePrice<=item.salePrice) return 'danger'
      }catch (err) {
        return {'text-info': true}
      }

    },
    // setToWbArtWb(item) {
    //   const {art_wb, seller, salePrice:needPrice} = item
    //
    //   fetch(`http://${process.env.VUE_APP_API_SERVER}:${process.env.VUE_APP_API_PORT}/report/setToWbArtWb`, {
    //     method: 'POST',
    //     mode: 'cors',
    //     cache: 'no-cache',
    //     credentials: 'same-origin',
    //     headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
    //     body: JSON.stringify({art_wb, seller, needPrice})
    //   })
    //       .then(resp => {
    //         if (resp) alert('добавлен')
    //       })
    //  },
    calcColWidth(key) {
      if (key === 'salePrice') return "width:100px"
      if (key === 'vipName') return "width:150px"
      if (key === 'gays') return "width:470px"
      if (key === 'sales') return "width:100px"
      // return "{ width: field.key === 'sales' ? '120px' : '180px' }"
      // return "width:80px"
    },
    getArtClass: function(value) {
      if (value>0) return 'text-success'
      if (value<=0) return 'text-danger'
    }
  },
  mounted() {
  },
  computed: {

  }
}
</script>