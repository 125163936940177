<template>
  <b-row>
    <b-col style="max-width: 200px;border: 0px solid #f00">
      <a :href="'https://www.wildberries.ru/catalog/' + artwb + '/detail.aspx?targetUrl=XS'" target="_blank">
        {{info.radius}} {{info.dio}} {{info.color}} | {{artwb}}</a>
      <Ostatki :barcode="item.barcode" :artwb="artwb" :key="'ostatki-'+item.barcode" />

    </b-col>
    <b-col style="max-width: 300px;border: 0px solid #f00">
      <normalprice-artwb :artwb="artwb"  :zakup="info.zakup" :seller="item.seller"  />
      <promo-artwb v-if="info.curPromo" :artwb="artwb" :zakup="info.zakup" :seller="info.seller" />
    </b-col>
<b-col>

</b-col>
<b-col>
  <conc-artwb :artwb="artwb" :conc="info.subSellers" />
</b-col>




  </b-row>
</template>
<script>
import Ostatki from "@/components/products/cols/Ostatki";
import PromoArtwb from "@/components/products/cols/PromoWb";
import NormalpriceArtwb from "@/components/products/cols/NormalWb";
import ConcArtwb from "@/components/products/cols/Conc";

export default {
name: 'row-artwb',
props: ["artwb", "item"],
  components:{ConcArtwb, NormalpriceArtwb, PromoArtwb, Ostatki},
data: () => ({
isLoading: false,
curShow: "Цены и промо",
  info: {radius:'d', dio: '', color: '', art_wb:'', zakup: 0, salePrice:0, curPromo: '', subSellers: []}
}),
  methods: {
  async getInfo() {
    fetch(`${process.env.VUE_APP_SERVER_API}/report/artInfo/${this.artwb}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
    })
        .then(resp => resp.json())
        .then(resp => {
          this.info=resp
        })
  }
  },
async mounted() {
this.isLoading = true
  await this.getInfo()
}
}
</script>